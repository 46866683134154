/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  background-color: var(--color-background-main);
}

body,
#root,
.app-wrapper {
  min-height: 100vh;
  background-color: var(--color-background-main);
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: var(--color-text-primary);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow-x: hidden;
  /* Remove any overflow-y: hidden if it exists, and ensure default scrolling behavior */
  overflow-y: auto;
}

#root {
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  /* height of footer plus padding */
}

/* Main container styles */
.main-container,
.app-container,
.page-container {
  min-height: 100vh;
  width: 100%;
  background-color: var(--color-background-main);
  display: flex;
  flex-direction: column;
  /* Ensure containers can scroll if content exceeds viewport */
  overflow-y: auto;
}

/* Links */
a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text-primary);
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
}

/* Reset lists */
ul,
ol {
  list-style: none;
}

/* Form elements */
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
}

button {
  cursor: pointer;
  font-family: inherit;
}

/* Focus states */
:focus-visible {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}

:focus:not(:focus-visible) {
  outline: none;
}

/* Component base styles */
.card,
.dialog,
.modal {
  background-color: var(--color-background);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}

/* Layout */
.app-content {
  flex: 1;
  margin-top: 60px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-background-main);
  min-height: calc(100vh - 140px);
  padding: 0;
  margin-bottom: 0;
  position: relative;
  /* Ensure content area can scroll */
  overflow-y: auto;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  background-color: transparent;
}

/* Utility classes */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

/* New class for static pages that need to prevent scrolling */
.no-scroll {
  overflow: hidden !important;
}

/* Toast types: success, error, warning, info */
.custom-toast {
  background: white !important;
  color: #333 !important;
  border-radius: 4px !important;
  padding: 16px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.custom-toast-success {
  border-left: 4px solid #10B981 !important;
  /* Green */
}

.custom-toast-error {
  border-left: 4px solid #EF4444 !important;
  /* Red */
}

.custom-toast-warning {
  border-left: 4px solid #F59E0B !important;
  /* Yellow */
}

.custom-toast-info {
  border-left: 4px solid #3B82F6 !important;
  /* Blue */
}

/* Toast content styles */
.toast-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.toast-icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.toast-message {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

/* Progress bar customization */
.Toastify__progress-bar {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)) !important;
}

/* Media queries */
@media (max-width: 768px) {
  .container {
    padding: 0 0.5rem;
  }

  .app-content {
    margin-top: 50px;
    min-height: calc(100vh - 120px);
  }
}

/* Ensure white backgrounds only on specific components */
.white-bg {
  background-color: var(--color-background);
}

/* Fix for iOS height issues */
@supports (-webkit-touch-callout: none) {
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
}

/* Add this temporarily at the bottom of your global.css */
/* [style*="background"] {
  outline: 3px solid red !important;
}

*[class*="module_"] {
  outline: 1px solid blue;
} */