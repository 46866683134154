/* Enhanced CSS for CollaborationPortal.module.css */
.collaborationPortal {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.sessionHeader {
    padding: 16px 24px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sessionTitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 4px 0;
    color: #2c3e50;
}

.sessionMeta {
    display: flex;
    gap: 16px;
    font-size: 0.875rem;
    color: #64748b;
}

.sourceSystem {
    display: inline-flex;
    align-items: center;
}

.statusBadge {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 6px;
    text-transform: capitalize;
}

.open {
    background-color: #e3f2fd;
    color: #1976d2;
}

.in_progress {
    background-color: #fff8e1;
    color: #ff8f00;
}

.resolved {
    background-color: #e8f5e9;
    color: #388e3c;
}

.statusControl {
    min-width: 140px;
}

.statusSelect {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #fff;
    font-size: 0.875rem;
}

.messagesThread {
    flex: 1;
    padding: 24px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.message {
    max-width: 80%;
    padding: 12px 16px;
    border-radius: 12px;
    position: relative;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.incoming {
    align-self: flex-start;
    margin-right: auto;
    background-color: #fff;
    border: 1px solid #e0e0e0;
}

.outgoing {
    align-self: flex-end;
    margin-left: auto;
    background-color: #e3f2fd;
    color: #1976d2;
}

.systemMessage {
    align-self: center;
    max-width: 60%;
    text-align: center;
    background-color: #f1f3f5;
    color: #64748b;
    font-style: italic;
    font-size: 0.875rem;
    border: none;
    box-shadow: none;
}

.analytics_hub_user {
    border-left: 4px solid #f59e0b;
    /* Amber accent color */
}

.ryze_developer {
    border-left: 4px solid #2563eb;
    /* Blue primary color */
}

.messageHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}

.userName {
    font-weight: 600;
    color: #334155;
}

.userType {
    font-size: 0.75rem;
    color: #64748b;
    text-transform: capitalize;
}

.messageContent {
    line-height: 1.5;
    word-break: break-word;
}

.messageTime {
    font-size: 0.75rem;
    color: #94a3b8;
    margin-top: 6px;
    text-align: right;
}

.attachments {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.attachment {
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 6px;
}

.attachmentLink {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: inherit;
}

.attachmentName {
    font-weight: 500;
}

.attachmentSize {
    font-size: 0.75rem;
    color: #64748b;
}

.noMessages {
    align-self: center;
    padding: 32px;
    color: #64748b;
    font-style: italic;
}

.messageForm {
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
    padding: 16px 24px;
}

.messageInput {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px;
    font-family: inherit;
    font-size: 0.9375rem;
    resize: none;
    margin-bottom: 12px;
}

.messageInput:focus {
    outline: none;
    border-color: #2563eb;
}

.messageInput:disabled {
    background-color: #f8fafc;
    color: #94a3b8;
}

.messageActions {
    display: flex;
    justify-content: flex-end;
}

.sendButton {
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 8px 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.sendButton:hover:not(:disabled) {
    background-color: #1d4ed8;
}

.sendButton:disabled {
    background-color: #cbd5e1;
    cursor: not-allowed;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    gap: 16px;
    color: #64748b;
}

.loadingSpinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f4f6;
    border-top: 3px solid #2563eb;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.errorContainer {
    max-width: 500px;
    margin: 100px auto;
    padding: 30px;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.errorContainer h2 {
    color: #ef4444;
    margin-bottom: 16px;
}

.returnButton {
    margin-top: 24px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-weight: 500;
    cursor: pointer;
}

.returnButton:hover {
    background-color: #1d4ed8;
}