/* HomePage.module.css */
.fullPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f8fafc 0%, #e2e8f0 100%);
  text-align: center;
  overflow: hidden;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding: 0 var(--spacing-lg);
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
  transform: translateY(20px);
}

.logo {
  width: 180px;
  height: auto;
  margin-bottom: var(--spacing-sm);
  max-width: none;
  animation: fadeIn 1s ease-out forwards;
  opacity: 0;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-sm);
  line-height: 1.2;
}

.highlight {
  color: var(--color-accent);
}

.subtitle {
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-sm);
}

.description {
  font-size: 0.95rem;
  color: var(--color-text-muted);
  margin-bottom: var(--spacing-xl);
  max-width: 480px;
}

.buttonGroup {
  display: flex;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.login {
  composes: buttonPrimary from '../../styles/components.module.css';
  min-width: 130px;
  padding: var(--spacing-sm) var(--spacing-lg);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.register {
  composes: buttonSecondary from '../../styles/components.module.css';
  min-width: 130px;
  padding: var(--spacing-sm) var(--spacing-lg);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.login:hover,
.register:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.oauthWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
  max-width: 280px;
}

/* Optional footer if you want one */
.footer {
  position: absolute;
  bottom: var(--spacing-md);
  font-size: 0.75rem;
  color: var(--color-text-muted);
}

/* Animations */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .subtitle {
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
  }

  .description {
    font-size: 0.85rem;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
  }

  .logo {
    width: 120px;
    /* smaller for mobile */
    margin-bottom: 12px;
    /* reduce spacing */
  }

  .buttonGroup {
    flex-direction: column;
    gap: 12px;
  }

  .login,
  .register {
    width: 100%;
    font-size: 1rem;
    padding: 12px;
  }

  .oauthWrapper {
    gap: 10px;
    max-width: 100%;
    padding: 0 1rem;
  }

  .centeredContent {
    padding: 1rem;
    max-width: 100%;
  }

  .fullPage {
    padding: 1rem 0;
    /* adds vertical breathing room */
  }
}